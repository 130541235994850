import React from "react"
import {Link} from 'gatsby'
import {
  DecorationDiv,
  DownloadDiv,
  DownloadP,
  DownloadImg,
  QuestionP
} from "./AboutStyle"

import AppStore from "../../images/image-app-store.png"
import PlayStore from "../../images/image-play-store.png"

function Decoration({ props }) {
  const { Download, Question1, Question2 } = props.Decoration
  return (
    <>
      <DecorationDiv>
        <DownloadDiv>
          <DownloadP>{Download}</DownloadP>
          <div style={{ display: "flex" }}>
            <a
              className="mr-2"
              href="https://transfez.onelink.me/e9Ud/web3a?page=send"
            >
              <DownloadImg src={AppStore} alt="App Store" />
            </a>
            <a href="https://transfez.onelink.me/e9Ud/web3b?page=send">
              <DownloadImg src={PlayStore} alt="Play Store" />
            </a>
          </div>
        </DownloadDiv>
          <QuestionP>{Question1} <Link to="/en/contact-us">{Question2}</Link></QuestionP>
      </DecorationDiv>
    </>
  )
}

export default Decoration
