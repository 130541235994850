import styled from 'styled-components'
import { Button } from 'react-bootstrap'

export const UnderlineStyled = styled.div`
  width: ${props => props.width || "40px"};
  height: ${props => props.height || "5px"};
  background-color: ${props => props.bcolor || "#ffd27c"};
  margin-top: ${props => props.mt || "0px"};
  margin-bottom: ${props => props.mb || "0px"};
  margin-left: auto;
  margin-right: auto;
  @media (min-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
`

export const DotStyled = styled.div`
  width: ${props => props.width || "10px"};
  height: ${props => props.height || "10px"};
  border-radius: ${props => props.bradius || "5px"};
  background-color: ${props => props.bcolor || "#ffd27c"};
  display: inline-block;
  margin-left: ${props => props.ml || "0.25rem"};
`

export const ButtonStyled = styled(Button)`
  font-family: "GothamMedium";
  background-color: ${p => p.bcolor || "#ffd27c"};
  font-size: ${p => p.fsize || ".875rem"};
  color: ${p => p.color || "#166f7b"};
  font-weight: medium;
  border-radius: ${p => p.bradius || "10px"};
  border: none;
  padding: ${p => p.padding || "10px 20px"};
  transition: 200ms;
  -webkit-box-shadow: -1px 7px 5px -4px #a0a0a0;
  box-shadow: -1px 7px 5px -4px #a0a0a0;
  outline: none!important;
  :hover,
  :focus,
  :active {
    outline: none!important;
    border: none!important;
    transition: 200ms;
    background-color: ${p => p.bcolorres || "#166f7b"}!important;
    color: ${p => p.colorres || "#ffd27c"}!important;
    -webkit-box-shadow: -1px 7px 5px -4px #a0a0a0!important;
    box-shadow: -1px 7px 5px -4px #a0a0a0!important;
  }
`