import React from "react"
import Slider from "react-slick"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Container from "react-bootstrap/Container"
import { MediaSection, TestimoniesH2, MediaImg } from "./SendMoneyStyle"
import { MediaNextArrow, MediaPrevArrow } from "../Reusable/slick-arrow"
import { IdComponent } from "../Reusable/id-components"
import { BackgroundImg } from "../HomeComponents/HowToUse"
import bgGreySmall from "../../images/logo-small-grey.svg"

function Media({ mainPage }) {
  const data = useStaticQuery(graphql`
    {
      events: allStrapiMediaPartners {
        nodes {
          id
          title
          source
          image {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const dataLength = data.events.nodes.length
  const newData =
    dataLength < 5
      ? data.events.nodes.concat(data.events.nodes)
      : data.events.nodes

  var InitSettings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000,
    arrow: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "1px",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          centerMode: true,
          centerPadding: "1px",
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
    nextArrow: <MediaNextArrow />,
    prevArrow: <MediaPrevArrow />,
  }
  return (
    <>
      <IdComponent title="media" />
      <MediaSection pt={"150px"}>
        <CustomContainer>
          <MainTitle>Media</MainTitle>
          <StyledSlider
            {...InitSettings}
            style={{
              backgroundColor: "white",
              padding: "60px 15px 60px 15px",
              boxShadow: "2px 4px 24px -10px #828282",
              borderRadius: "20px",
            }}
          >
            {newData.map(event => (
              <div key={event.id}>
                <a target={event.title} href={event.source}>
                  <MediaImg
                    loading="lazy"
                    src={event?.image?.childImageSharp?.fluid?.src}
                    alt={event.title}
                  />
                </a>
              </div>
            ))}
          </StyledSlider>
          <BackgroundImg
            src={bgGreySmall}
            alt="logo"
            left="-300px"
            top="200px"
            width="600px"
          />
        </CustomContainer>
      </MediaSection>
    </>
  )
}

export default Media

const StyledSlider = styled(Slider)`
  .slick-track {
    display: flex !important;
    align-items: center !important;
  }
`

const CustomContainer = styled(Container)`
  padding: 0px;
  position: relative;
  @media (max-width: 768px) {
    padding: 0px 25px;
  }
`

const MainTitle = styled.h2`
  color: #102c57;
  font-size: 50px;
  font-family: "Typefez-extrabold";
  text-align: center;
  margin-bottom: 50px;
  @media (max-width: 576px) {
    font-size: 38px;
  }
`
