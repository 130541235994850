import React from 'react'
import { UnderlineStyled, DotStyled } from './style'

export const CustomUnderline = ({ width, height, mt, mb, bcolor }) => {
    return (
        <UnderlineStyled width={width} height={height} mt={mt} mb={mb} bcolor={bcolor}/>
    )
}

export const CustomDot = ({ width, height, bcolor, ml, bradius }) => {
    return (
        <DotStyled width={width} height={height} ml={ml} bcolor={bcolor} bradius={bradius}/>
    )
}
