import React from "react"
import "../components/layout.css"
import { useLocation } from "@reach/router"

import ReasonSection from "../components/ReasonSection/ReasonSection"
import AboutSection from "../components/AboutSection/AboutSection"
import Decoration from "../components/AboutSection/Decoration"
import Footer from "../components/FooterComponents/Footer"

import { MainPageJson, PathJson, AboutUsJson } from "../components/location"
import Seo from "../components/SEOComponents/Seo"
import Download from "../components/HomeComponents/Download"
import { HeaderAboutUs } from "../components/AboutSection/Header"
import { ProblemSolving } from "../components/AboutSection/ProblemSolving"
import Investor from "../components/HomeComponents/Investor"
import Media from "../components/SendMoney/Media"
import Partner from "../components/HomeComponents/Partner"
import { NavigationBar } from "../components/NavigationBar/parent"

function AboutUs() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const AboutUsContent = AboutUsJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")
  return (
    <div className="maxwidth">
      <Seo title="about-us" />
      <NavigationBar />
      <HeaderAboutUs props={AboutUsContent.Header} />
      <ReasonSection props={AboutUsContent} isEnglish={isEnglish} />
      <Partner />
      <Media />
      <Investor isEnglish={isEnglish} />
      <ProblemSolving props={AboutUsContent.Solving} />
      <div style={{ marginBottom: 100, marginTop: 70 }}>
        <Download
          props={MainPageContent.Download}
          propsAbout={AboutUsContent.Download}
        />
      </div>
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default AboutUs
