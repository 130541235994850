import React from "react"
import { Container } from "react-bootstrap"
import styled from "styled-components"

export const HeaderAboutUs = ({ props }) => {
  const { Title, Desc } = props || {}
  return (
    <MainDiv>
      <MainContainer>
        <MainTitle>{Title}</MainTitle>
        <MainDesc>{Desc}</MainDesc>
      </MainContainer>
    </MainDiv>
  )
}

const MainDiv = styled.div`
  margin-top: 80px;
  padding-top: 40px;
`

const MainContainer = styled(Container)`
  width: 40%;
  text-align: center;
  @media (max-width: 1200px){
    width: 50%;
  }
  @media (max-width: 992px){
    width: 60%;
  }
  @media (max-width: 768px){
    width: 80%;
  }
  @media (max-width: 576px){
    width: 90%;
  }
`

const MainTitle = styled.h2`
  font-size: 50px;
  font-family: "Typefez-extrabold";
  color: #909098;
  margin-bottom: 20px;
  @media (max-width: 992px){
    font-size: 42px;
  }
`

const MainDesc = styled.h5`
  font-size: 16px;
  font-family: "Typefez-med";
  color: #102C57;
`
