import styled from "styled-components"
import { Container, Col } from "react-bootstrap"

export const AboutSectionStyled = styled.section`
  padding-top: 3rem;
  background-image: url(${props => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right bottom;
  position: relative;
  padding-bottom: 100px;
  color: white;
  @media (max-width: 414px) {
    background-image: url(${props => props.bgres});
    background-position: center bottom;
    padding-bottom: 15px;
    margin-bottom: unset;
  }
`

export const AboutContainer = styled(Container)`
  text-align: center;
  @media (min-width: 576px) {
    max-width: 540px;
  }
  @media (min-width: 768px) {
    max-width: 720px;
    text-align: left;
  }
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`

export const AboutCol = styled(Col)`
  @media (min-width: 768px) {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
`

export const AboutH3 = styled.h2`
  font-family: "GothamMedium";
  margin-bottom: 1.5rem;
  font-size: 1.75rem;
`

export const AboutP = styled.p`
  color: white;
`

export const AboutSpan = styled.span`
  font-family: "GothamMedium";
  color: #ffd27c;
  font-size: 1.25rem;
`

export const AboutPH2 = styled.h4`
  font-family: "GothamMedium";
  font-size: 2rem;
  color: white;
  @media (min-width: 768px) {
    margin-top: 3rem;
  }
  @media (max-width: 414px) {
    font-size: 22px;
    margin-top: 200px;
  }
`

export const DecorationDiv = styled.div`
  position: relative;
  left: 0;
  right: 0;
  top: -90px;
  margin-bottom: -100px;
  @media (max-width: 414px) {
    bottom: unset;
    position: relative;
    top: 0;
    margin-bottom: 0;
  }
`
export const DownloadDiv = styled.div`
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  width: max-content;
  padding-left: 10%;
  padding-right: 10%;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 40px;
  background-color: #ffd27c;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 20%);
  @media (max-width: 414px) {
    width: 100%;
    padding-left: 15px;
  }
`

export const DownloadP = styled.p`
  font-family: "GothamBook";
  text-align: center;
  color: #48a9b5;
  @media (min-width: 768px) {
    text-align: left;
  }
`

export const DownloadImg = styled.img`
  max-width: 100%;
  height: auto;
`

export const QuestionP = styled.p`
  color: #6c6365;
  margin-left: 10%;
  text-align: center;
  font-family: "GothamBook";
  @media (max-width: 414px) {
    margin-left: 15px;
    margin-right: 15px;
  }
  @media (min-width: 768px) {
    text-align: left;
  }
`
