import React from "react"
import { Col, Container, Row } from "react-bootstrap"

import imgEdu from "../../images/about-us-tag.png"
import imgFam from "../../images/about-us-diagram.png"
import imgBus from "../../images/about-us-pencil.png"
import imgGlobe from "../../images/about-us-big-globe.png"
import styled from "styled-components"

function ReasonSection({ props, isEnglish = false }) {
  const {
    Title,
    Education1,
    Education2,
    Family1,
    Family2,
    Business1,
    Business2,
  } = props.Reasons

  const dataRendering = [
    {
      title: Business1,
      desc: Business2,
      background: "#5A489C",
      icon: imgBus,
    },
    {
      title: Education1,
      desc: Education2,
      background: "#FF7B5A",
      icon: imgEdu,
    },
    {
      title: Family1,
      desc: Family2,
      background: "#2EBCD0",
      icon: imgFam,
    },
  ]

  return (
    <Container style={{ position: "relative" }}>
      <ImgBig alt="globe" src={imgGlobe} />
      <BigRow margin={!isEnglish && "370px 0px 200px 0px"}>
        <Col md={4}></Col>
        <Col md={8}>
          <BigTitle>{Title}</BigTitle>
        </Col>
      </BigRow>
      <Row>
        {dataRendering.map((item, index) => {
          const { title, desc, background, icon } = item || {}
          const isFirst = index === 0
          return (
            <Col lg={4} style={{ padding: 0 }} key={index}>
              <BoxDiv bcolor={background}>
                <div style={{ position: "relative" }}>
                  <TextDiv width="100%">
                    <MainTitle fsize={"32px"}>{title}</MainTitle>
                    <MainDesc>{desc}</MainDesc>
                  </TextDiv>
                  <ImgCustom
                    src={icon}
                    alt={title}
                    width={isFirst && "100px"}
                    width992={isFirst && "120px"}
                    width768={isFirst && "110px"}
                  />
                </div>
              </BoxDiv>
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}

export default ReasonSection

const BoxDiv = styled(Row)`
  position: relative;
  overflow: hidden;
  padding: 30px 25px;
  height: 350px;
  background-color: ${p => p.bcolor};
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  margin: ${p => p.margin || "10px"};
  @media (max-width: 992px) {
    margin: ${p => p.margin992};
  }
`

const BigRow = styled(Row)`
  margin: ${p => p.margin || "370px 0px 100px 0px"};
  @media (max-width: 576px) {
    margin: 100px 0px 30px 0px;
  }
`

const BigTitle = styled.h2`
  color: #102c57;
  font-size: 44px;
  font-family: "Typefez-extrabold";
  @media (max-width: 768px) {
    font-size: 32px;
    text-align: center;
  }
`

const ImgBig = styled.img`
  position: absolute;
  top: -280px;
  left: -250px;
  width: 650px;
  @media (max-width: 576px) {
    display: none;
  }
`

const TextDiv = styled.div`
  width: ${p => p.width || "70%"};
`

const MainTitle = styled.h4`
  font-size: ${p => p.fsize || "40px"};
  color: white;
  font-family: "Typefez-bold";
  margin-bottom: 30px;
  @media (max-width: 576px) {
    font-size: 32px;
  }
`

const MainDesc = styled.h5`
  font-size: 20px;
  color: white;
  font-family: "Typefez-reg";
`

const ImgCustom = styled.img`
  position: absolute;
  bottom: -40px;
  right: -25px;
  width: ${p => p.width || "170px"};
  @media (max-width: 992px) {
    width: ${p => p.width992 || "220px"};
    bottom: -35px;
  }
  @media (max-width: 768px) {
    width: ${p => p.width768 || "170px"};
    bottom: -50px;
  }
`
