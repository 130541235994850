import React from "react"
import { Container } from "react-bootstrap"
import phoneImg from "../../images/about-us-phone.png"
import styled from "styled-components"

export const ProblemSolving = ({ props }) => {
  const { Title, Title1, Title2 } = props || {}
  return (
    <Container style={{ marginTop: 200 }}>
      <div className="d-flex justify-content-center">
        <MainTitle>{Title}</MainTitle>
      </div>
      <MainDiv>
        <div>
          <SecondTitle>{Title1}</SecondTitle>
          <br />
          <SecondTitle>{Title2}</SecondTitle>
        </div>
        <ImgStyled src={phoneImg} alt="phone" />
      </MainDiv>
    </Container>
  )
}

const MainDiv = styled.div`
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 18px;
  background-color: #333334;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const MainTitle = styled.h2`
  font-family: "Typefez-black";
  font-size: 42px;
  color: #102c57;
  margin-bottom: 50px;
  width: 80%;
  text-align: center;
  @media (max-width: 576px) {
    font-size: 28px;
    margin-bottom: 35px;
  }
`

const SecondTitle = styled.h4`
  font-family: "Typefez-med";
  font-size: 18px;
  color: white;
  @media (max-width: 576px) {
    font-size: 16px;
  }
`

const ImgStyled = styled.img`
  padding: 0px 24px;
  width: 350px;
  @media (max-width: 768px) {
    padding: 30px 24px 0px 24px;
    width: 250px;
  }
`
