import styled from "styled-components"
import { Col, Button, Container } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const MainTitle = styled.h2`
  font-family: "Typefez-bold";
  font-size: 40px;
  color: ${p => p.color || "#fff"};
  margin-bottom: ${p => p.mbottom || "50px"};
  @media (max-width: 992px){
    font-size: 32px;
  }
`

export const TitleH1 = styled.h2`
  font-family: "GothamMedium";
  color: #48a9b5;
  text-align: center;
  font-size: 2.5rem;
  @media (min-width: 768px) {
    text-align: left;
  }
`

export const DescH3 = styled.h3`
  font-family: "GothamBook";
  color: #48a9b5;
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
`

export const CostSection = styled.section`
  position: relative;
  padding-bottom: 200px;
  color: #fff !important;
  background-color: #48a9b5;
  background: linear-gradient(180deg, #48a9b5 0%, #196e97 100%);
`

export const CostH3 = styled.h2`
  font-size: 36px;
  font-family: "GothamMedium";
  text-align: center;
  margin-bottom: 1.5rem;
  @media (min-width: 992px) {
    text-align: left;
  }
`

export const CostSpan = styled.span`
  font-family: "GothamMedium";
  color: #ffd27c;
`

export const CostP = styled.p`
  color: #fff;
`

export const PricePanel = styled.div`
  max-width: 190px;
  min-height: 600px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
`

export const PriceTitle = styled.p`
  color: #ffd27c;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.75rem;
`

export const PanelDiv = styled.div`
  background-color: #196e97;
  padding: 1rem;
  position: relative;
  flex: 1 1 auto;
  border-radius: 0.25rem;
`

export const PanelH5 = styled.div`
  margin-bottom: 113px;
  font-family: "GothamMedium";
  text-align: center;
  font-size: 1.25rem;
`

export const PanelSmall = styled.small`
  text-align: center;
  display: block;
  font-family: "GothamBook";
  color: #ffd27c;
`

export const PanelWhiteSquare = styled.div`
  position: absolute;
  top: 56px;
  left: -1rem;
  right: -1rem;
  font-family: "GothamMedium";
  font-size: 1.7rem;
  color: #196e97;
  text-align: center;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.25rem;
  background-color: rgb(255, 255, 255);
  @media (min-width: 992px) {
    padding: 1.5rem;
  }
`

export const CaretYellowImg = styled.img`
  width: 50px;
  position: absolute;
  top: -12px;
  left: 85px;
`

export const CostCloud = styled.img`
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  width: 100%;
  margin: auto;
  max-width: 1920px;
  @media (max-width: 576px) {
    /* width: auto; */
    min-height: 450px;
    object-fit: cover;
  }
`

export const TestimoniesSection = styled.section`
  position: relative;
`

export const TestimoniesCol1 = styled(Col)`
  margin-bottom: 3rem;
  @media (min-width: 768px) {
    margin-right: 3rem;
    margin-bottom: 0;
  }
`
export const TestimoniesCol2 = styled(Col)`
  text-align: center;
  @media (min-width: 768px) {
    text-align: left;
  }
`

export const TestimoniesImg = styled.img`
  max-width: 100%;
  height: auto;
`

export const TestimoniesH2 = styled.h2`
  text-align: ${props => props.align || "left"};
  font-size: 50px;
  font-family: "Typefez-extrabold";
  color: #102C57;
  margin-bottom: 1.5rem;
  text-align: center;
  @media (min-width: 768px) {
    text-align: ${props => props.alignres || "left"};
  }
  @media (max-width: 414px) {
    font-size: 30px;
  }
`

export const TestimoniesBr = styled.br`
  display: none;
  @media (min-width: 768px) {
    display: inline;
  }
`

export const TestimoniesButton = styled(Button)`
  font-family: "GothamBook";
  background-color: #196e97;
  border-color: #196e97;
  color: #ffd27c;
  text-transform: uppercase;
  padding: 1rem;
  margin-bottom: 1rem;
  margin-right: 10px;
  :hover {
    background-color: #196e97;
    border-color: #196e97;
    color: #ffc107;
  }
  :active {
    background-color: #196e97;
    border-color: #196e97;
    color: #ffc107;
  }
  :focus {
    background-color: #196e97;
    border-color: #196e97;
    color: #ffc107;
  }
`

export const MediaSection = styled.section`
  padding-top: ${props => props.pt || "3rem"};
  padding-bottom: 3rem;
`

export const MediaImg = styled.img`
  transition: all 300ms ease;
  width: 100%;
  max-height: 40px;
  padding-left: 15%;
  padding-right: 15%;
  filter: grayscale(100%);
  :hover {
    filter: none;
  }
  @media (max-width: 576px){
    height: auto;
  }
`

export const DeliveryImg = styled.img`
  max-width: 100%;
  height: auto;
`

export const DeliveryH3 = styled.h2`
  font-size: 36px;
  font-family: "GothamBold";
  color: #48a9b5;
  margin-bottom: 1rem;
`

export const RouteCol = styled(Col)`
  align-items: center;
  display: flex;
`

export const RouteSmall = styled.small`
  display: flex;
  font-family: "GothamBook";
  color: #48a9b5;
  text-transform: uppercase;
  :after {
    content: ":";
    color: #48a9b5;
  }
`

export const SeparatorImg1 = styled.img`
  padding-right: 38px;
  max-width: 100%;
  height: auto;
`

export const SeparatorImg2 = styled.img`
  position: absolute;
  max-width: 40px;
  top: ${props => props.posx};
  right: 20px;
  height: auto;
  @media (max-width: 414px) {
    top: ${props => props.posxres};
  }
`

export const FlagCol = styled(Col)`
  align-items: center;
  display: flex;
`

export const CountryFlag = styled.img`
  border: 1px solid black;
  border-radius: 5px;
`

export const FontAwesome = styled(FontAwesomeIcon)`
  font-size: 1.8rem;
  color: #48a9b5;
  margin-left: 1rem;
  margin-right: 1rem;
`

export const EstimateCol = styled(Col)`
  color: #6c6365;
  font-family: "GothamMedium";
  align-items: center;
  display: flex;
`

export const InformationCol = styled(Col)`
  margin-bottom: 1rem;
  color: #48a9b5;
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`

export const InformationLeft = styled.div`
  font-family: "GothamBook";
  background-color: rgba(72, 169, 181, 0.15);
  position: relative;
  text-align: center;
  padding: 1rem;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`

export const MaxAmount = styled.p`
  font-family: "GothamBold";
  color: #196e97;
  font-size: 1.25rem;
`

export const AsteriskImg = styled.img`
  position: absolute;
  top: 0;
  left: -40px;
`

export const HowToContainer = styled(Container)`
  padding-top: 140px;
  padding-bottom: 3rem;
  position: relative;
  @media (max-width: 414px) {
    padding-top: 2rem;
  }
`

export const HowToH3 = styled.h2`
  max-width: 576px;
  font-size: 50px;
  font-family: "Typefez-black";
  color: #102C57;
  text-align: left;
  margin-bottom: 3rem;
  @media (max-width: 992px) {
    text-align: center;
    font-size: 42px;
  }
`

export const HowToDiv = styled.div`
  display: flex;
  flex-direction: column-reverse;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const HowToSquareDiv = styled.div`
  border-radius: 20px;
  width: 100%;
  padding: 1.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background-color: white;
  @media (min-width: 768px) {
    padding: 3rem;
  }
`

export const HowToButton = styled(Button)`
  margin-top: 20px;
  background-color: #ffd27c;
  border-color: #ffd27c;
  color: #48a9b5;
  text-transform: uppercase;
  font-family: "GothamBook";
  :hover {
    background-color: #ffd27c;
    border-color: #ffd27c;
    color: #48a9b5;
    filter: brightness(95%);
  }
  :active {
    background-color: #ffd27c;
    border-color: #ffd27c;
    color: #48a9b5;
    filter: brightness(90%);
  }
  :focus {
    background-color: #ffd27c;
    border-color: #ffd27c;
    color: #48a9b5;
    filter: brightness(90%);
  }
`

export const MainDiv = styled.div`
  box-shadow: 1px 1px 15px rgba(73, 171, 184, 0.2);
  -webkit-box-shadow: 1px 1px 15px rgba(73, 171, 184, 0.2);
  -moz-box-shadow: 1px 1px 15px rgba(73, 171, 184, 0.2);
  border-radius: 15px;
  /* margin: 15px; */
  padding: ${p => p.pad};
  margin: ${p => p.margin || "50px 0px 10px 0px"};
  @media (max-width: 576px) {
    width: 90%;
  }
`